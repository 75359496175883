.operationForm {
    margin: 25px 25px 25px 25px;
  }

.input-feedback {
  color: rgb(235, 54, 54);
  font-size: 14px;
  margin-bottom: 20px;
}

.tableMargin {
  margin: 100px 50px 25px 35px; 
}
  
.titlePosition {
  margin: 25px 25px 25px 25px;
}

.titleMarkedPosition {
  margin: 0px 25px 25px 25px;
}

.datePicker {
  margin: 25px 25px 25px 25px;
}

.tableMargin {
  margin: 100px 50px 25px 35px; 
}

.addButton {
  margin: 25px 25px 25px 25px;
}

.modalHarvestDate {
  margin: 25px 0px 0px 0px;
}

.modalProduceName {
  margin: 25px 25px 25px 25px;
}

.dot {
  height: 1rem;
  width: 1rem;
}

.select-item-label {
  vertical-align: 2px;
}

.produce-category-suggestion {
  margin-right: 0.5rem;
}
