.input-feedback {
  color: rgb(235, 54, 54);
  font-size: 14px;
  margin-bottom: 20px;
}

img {
  border: 1px solid #ddd;
  /* Gray border */
  border-radius: 4px;
  /* Rounded border */
  padding: 5px;
  /* Some padding */
  width: 150px;
  /* Set a small width */
}

/* Add a hover effect (blue shadow) */

img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.withBorder {
  border: 1px solid blue;
  /* Gray border */
  border-radius: 4px;
  /* Rounded border */
  padding: 5px;
  /* Some padding */
  width: 150px;
  /* Set a small width */
}

.formButton{
  margin: 75px 0px 0px 50px;
}

.newLandButton{
  margin: 75px 0px 0px 50px;
}

.newProduce {
  margin: 75px 0px 0px 0px;
}

.newOperationType {
  margin: 75px 50px 0px 0px;
}
