.marginJumbotron {
    margin: 50px 50px 30px 75px;
}

.marginSelect {
    margin: 55px 0px 25px 25px;
}

.marginExpense {
    margin: 25px 0px 25px 25px;
}

.marginIncome {
    margin: 25px 0px 25px 0px;
}

.marginSearchButton {
    margin: 25px 25px 0px 0px;
}

.marginShowButton {
    margin: 25px 55px 50px 25px;
}

.marginDetailButton {
    margin: 0px 10px 4px 10px;
}

.marginTable {
    margin: 25px 25px 25px 25px;
}

.printOnly{
    display:none;
}
.visibleTable{
    display : block;
}
.notVisibleTable{
    display: none;
}

@media print {
    header, footer {
        display: none;
    }
    .printHidden{
        display:none;
    }
    .printOnly{
        display:block;
    }
    .visiblePrint {
        display: block;
    }
}
