.form {
    margin: "15px";
    color: red;
}
.primaryBackground{
    margin: "25px";
}

.iconContext {
    color: "primary";
    margin: 10px 10px 10px 10px;
    font-size: 35px;
}
.NavLinkMargin {
    margin: 5px 5px 5px 5px;
}
.cardContainer {
    margin: 25px 25px 25px 25px;
}
.image {
    width: 250px;
    height: "auto";
}
